// src/firebase.js
import { initializeApp } from 'firebase/app';
import { getAuth, GoogleAuthProvider, OAuthProvider } from 'firebase/auth';
import { getDatabase } from 'firebase/database';
import { getStorage } from 'firebase/storage';

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCpQWc6Wc4opodjtiSznCDHu_ujxCXw6FU",
  authDomain: "amresh-media.firebaseapp.com",
  databaseURL: "https://amresh-media-default-rtdb.firebaseio.com",
  projectId: "amresh-media",
  storageBucket: "amresh-media.appspot.com",
  messagingSenderId: "725032969788",
  appId: "1:725032969788:web:663466c45862cb881f8539",
  measurementId: "G-Y7KVEBXCPQ"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize services
const auth = getAuth(app);
const googleProvider = new GoogleAuthProvider();
const microsoftProvider = new OAuthProvider('microsoft.com');
const database = getDatabase(app);
const storage = getStorage(app);

// Export services
export { app,auth, googleProvider, microsoftProvider, database, storage };
